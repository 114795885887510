import { css, cx } from '@emotion/css';
import { globalHeaderHeight, m, MotifScheme } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { useMobileLayout } from '../../../../hooks/useLayout';
import { CheeriosPamphlet } from '../CheeriosPamphlet/CheeriosPamphlet';
import { CheeriosSections } from '../CheeriosSections/CheeriosSections';
import { copyForwardSectionData } from './cheeriosDataUtils';
import { query } from './query';
import type { CheeriosBlockData } from './types';

interface Props {
  id: string;
}

const cheeriosBlockCss = css`
  background-color: ${m('--bg-color')};
  color: ${m('--fg-color')};
`;

/** Outer custom component. Reads from contentful and passes some constants. */
export const CheeriosBlock: FC<Props> = props => {
  const isMobile = useMobileLayout();

  const { data: rawData } = useContentfulQuery<
    CheeriosBlockData,
    ContentfulIdVariable & { isMobile: boolean }
  >(query, {
    variables: { id: props.id, isMobile },
  });

  const data = useMemo(() => {
    const copyForwardFilter = new Set<string>(['transitionTimingFunction']);
    return copyForwardSectionData(rawData, copyForwardFilter);
  }, [rawData]);

  if (!data) return null;
  return (
    <div
      data-type="block"
      className={cx(MotifScheme.SECONDARY, cheeriosBlockCss)}
      data-sysid={props.id}
    >
      <CheeriosPamphlet
        sectionHeights={data.cheeriosBlock.sectionsCollection.items.map(
          section => section.height ?? 1
        )}
        offsetTop={globalHeaderHeight}
      >
        <CheeriosSections sections={data.cheeriosBlock.sectionsCollection} />
      </CheeriosPamphlet>
    </div>
  );
};
